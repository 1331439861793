<div class="sidebar-panel">
  <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
    <!-- App Logo -->
    <div class="branding default-bg no-bg" style="background: transparent !important;">
      <!-- Two different logos for dark and light themes -->
      <img src="assets/images/luis-silverio.jpg" alt="" class="app-logo-text"
          *ngIf="themeService.activatedTheme?.name?.indexOf('dark') !== -1" style="display:none">
      <img src="assets/images/luis-silverio.jpg" alt="" class="app-logo-text"
           *ngIf="themeService.activatedTheme?.name?.indexOf('dark') === -1">
    </div>

    <!-- Sidebar user -->
    <div class="app-user">
      <div *ngIf="this.layoutConf.sidebarStyle === 'full'" style="font-size: 0.9em;font-weight: normal;color: white;">
        v{{version}}
      </div>
      <!-- Small buttons -->
      <div class="app-user-controls">
        <button class="text-muted"
                mat-icon-button mat-xs-button matTooltip="Settings"
                [matMenuTriggerFor]="appUserMenu">
          <mat-icon>settings</mat-icon>
        </button>
        <button class="text-muted" style="display:none"
                mat-icon-button  mat-xs-button matTooltip="Inbox"
                routerLink="/inbox">
          <mat-icon>email</mat-icon>
        </button>
        <button class="text-muted"
                mat-icon-button mat-xs-button matTooltip="Sign Out"
                (click)="signout()"
                >
          <mat-icon>exit_to_app</mat-icon>
        </button>

        <mat-menu #appUserMenu="matMenu">
          <button mat-menu-item (click)="navigateToProfile()">
            <mat-icon>account_box</mat-icon>
              <span>Profile</span>
            </button>
            <button mat-menu-item (click)="signout()">
              <mat-icon>exit_to_app</mat-icon>
              <span>Sign out</span>
            </button>
          </mat-menu>
      </div>
    </div>
    <!-- Navigation -->
    <app-sidenav (helpClicked)="openHelp($event)" [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle"></app-sidenav>
  </div>
</div>


<app-help #sideHelp></app-help>