import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import {AuthService} from '../auth.service';
import {Action} from '@ngrx/store';
import {ILoginModel} from '../models/i-login.model';

import * as userActions from './user.actions';
import {Router} from '@angular/router';
import {IAuthModel} from '../models/i-auth.model';
import {AuthGuard} from '../../../shared/services/auth/auth.guard';
import {ICreateUserModel} from '../models/create-user/i-create-user.model';
import {UserService} from '../user.service';
import {ICoreSearchModel} from '../../core/models/i-core-search.model';
import {IUserDashboardModel} from '../models/user-list/i-user-dashboard.model';
import {IRoleModel} from '../models/create-user/i-role.model';
import {IRoleAttributeModel} from '../models/create-user/i-role-attribute.model';
import {IUserModel} from '../models/i-user.model';
import {IUpdateUserModel} from '../models/create-user/i-update-user.model';
import {json} from 'ng2-validation/dist/json';
import { CacheService } from 'app/shared/services/CacheService';

@Injectable()
export class UserEffects {

  
  create$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(userActions.UserActionTypes.Create),
    map((action: userActions.Create) => action.payload),
    exhaustMap((m: ICreateUserModel) =>
      this.authService.create(m).pipe(
        map((loggedInUser: any) => (new userActions.CreateSuccess('success'))),
        catchError(err => of(new userActions.CreateFail(err)))
      )
    )
  ));

  
  update$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(userActions.UserActionTypes.Update),
    map((action: userActions.Update) => action.payload),
    exhaustMap((m: IUpdateUserModel) =>
      this.authService.update(m).pipe(
        map((loggedInUser: any) => (new userActions.UpdateSuccess('success'), new userActions.GetDashboardUsers(<ICoreSearchModel>{currentPage: 1, pageSize: 100}))),
        catchError(err => of(new userActions.UpdateFail(err)))
      )
    )
  ));

  
  login$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(userActions.UserActionTypes.Login), 
    map((action: userActions.Login) => action.payload),
    exhaustMap((loginModel: ILoginModel) =>
      this.authService.login(loginModel).pipe(
        tap((auth: IAuthModel) => (this.authService.setToken(auth.token), this.authGuard.doLogin(auth))),
        map((loggedInUser: any) => (new userActions.LoginSuccess(loggedInUser))),
        catchError(err => of(new userActions.ShowGlobalFeedback(err),new userActions.LoginFail(err))),
      )
    )
  ));

  
  logout$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(userActions.UserActionTypes.Logout),
    map((action: userActions.Logout) => action),
    tap(() => {
      this.authService.logout().pipe(
        catchError(err => of(new userActions.ShowGlobalFeedback(err))),
      )
      this.authGuard.logout(); 
      this.router.navigate(['/sessions/signin']);
    })
  ));

  
  loginSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(userActions.UserActionTypes.LoginSuccess), 
    tap(() => {
      if(this.authGuard.returnUrl != null && this.authGuard.returnUrl != '' && this.authGuard.returnUrl != '/dashboard'){
        this.router.navigate([this.authGuard.returnUrl]);
        return;
      }
      if(this.authGuard.isChairman() && !(this.authGuard.isBuyer() || this.authGuard.isDirector())){
        this.router.navigate(['/agenda/sourcing-session-agenda']);
        return;
      }
      this.router.navigate(['/induster']);
    })
  ), {dispatch: false});

  
  loginFail$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(userActions.UserActionTypes.LoginFail), 
    tap(() => window.location.reload())
  ), {dispatch: false});

  
  search$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(userActions.UserActionTypes.Search),
    map((action: userActions.Search) => action.payload),
    exhaustMap((search: string) =>
      this.userService.search(search).pipe(
        map((result: any[]) => (new userActions.SearchSuccess(result))),
        catchError(err => of(new userActions.SearchFail(err)))
      )
    )
  ));

  
  getUsers$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(userActions.UserActionTypes.GetUsers),
    exhaustMap(() =>
      this.cacheService.get('SAR.Users', this.userService.getUsers().pipe(
        map((result: IUserModel[]) => (new userActions.GetUsersSuccess(result))),
        catchError(err => of(new userActions.GetUsersError(err)))
      ))
    )
  ));

  
  getDashboardUsers$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(userActions.UserActionTypes.GetDashboardUsers),
    map((action: userActions.GetDashboardUsers) => action.payload),
    exhaustMap((search: ICoreSearchModel) =>
      this.userService.getDashboard(search).pipe(
        map((result: IUserDashboardModel[]) => (new userActions.GetDashboardUsersSuccess(result))),
        catchError(err => of(new userActions.GetDashboardUsersError(err)))
      )
    )
  ));

  
  getRoles$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(userActions.UserActionTypes.GetRoles),
    exhaustMap(() =>
      this.userService.getRoles().pipe(
        map((result: IRoleModel[]) => (new userActions.GetRolesSuccess(result))),
        catchError(err => of(new userActions.GetRolesError(err)))
      )
    )
  ));

  
  getRoleAttributes$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(userActions.UserActionTypes.GetRoleAttributes),
    exhaustMap(() =>
      this.userService.getRoleAttributes().pipe(
        map((result: IRoleAttributeModel[]) => (new userActions.GetRoleAttributesSuccess(result))),
        catchError(err => of(new userActions.GetRoleAttributesError(err)))
      )
    )
  ));

  
  getUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(userActions.UserActionTypes.GetUser),
    map((action: userActions.GetUser) => action.payload),
    exhaustMap((userId: string) =>
      this.userService.getById(userId).pipe(
        map((result: IUserModel) => (new userActions.GetUserSuccess(result))),
        catchError(err => of(new userActions.GetUserError(err)))
      )
    )
  ));

  constructor(private router: Router,
              private authGuard: AuthGuard,
              private authService: AuthService,
              private userService: UserService,
              private actions$: Actions,
              private cacheService: CacheService) {
  }
}
