import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IAppModuleModel, IAppSettingsModel } from '../models/i-app-setting.model';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private readonly appSettingsKey: string = 'App-Settings';

  constructor(private translate: TranslateService) {
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    if (appSettings && appSettings.locale){
      this.translate.setDefaultLang(appSettings.locale);
      this.translate.use(appSettings.locale);
    }
  }

  public setAppSettings(appSettings: IAppSettingsModel){
    // debugger;
    localStorage.setItem(this.appSettingsKey, JSON.stringify(appSettings));
    if (appSettings && appSettings.locale){
      this.translate.setDefaultLang(appSettings.locale);
      this.translate.use(appSettings.locale);
    }
  }

  public getAppSettings(): IAppSettingsModel{
    const appSettingsString = localStorage.getItem(this.appSettingsKey);
    return JSON.parse(appSettingsString) as IAppSettingsModel;
  }

  public getAppCurrencyCode(): string{
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    return appSettings.currencyCode;
  }

  public getAppLocale(): string{
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    return appSettings.locale;
  }

  public getAvailableModules(): IAppModuleModel[]{
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    return appSettings?.modules;
  }

  public getPlantsSingleMode(): boolean{
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    return appSettings.plantsSingleMode;
  }

  public getStorageLocationsSingleMode(): boolean{
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    return appSettings.storageLocationsSingleMode;
  }

  public getFormConfigs(): any[]{
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    return appSettings?.modules.map(x => x.settings?.formConfigs);
    // return [
    //   {
    //     optionalGroup: {
    //       costCentre: false,
    //       eProj: false,
    //       par: false,
    //       parLineItem: false,
    //       itemDescriptionPartNumber: false
    //     }
    //   },
    // ]
  }
  
}
