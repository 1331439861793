import {throwError} from 'rxjs';
import {IFeedbackModel} from '../models/i-feedback.model';

export class HttpUtils {
  static urlEncodeObject(obj): string {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p) && obj[p] !== null) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

  /**
  * @ngdoc function
  * @name handleHttpClientError
  * @methodOf HttpUtils
  * @location /app/views/core/utils
  * @description static method to handle http errors.
  * Returns a IFeedbackModel observable. It can be used for composing with other
  * Observables, such as in a map or mergeMap.
  */
  static handleHttpClientError(err : any) {
    if (err && err.error) {
      const feedback = <IFeedbackModel> {
        ...err.error
      }; 
      console.log('ERR', err, JSON.stringify(feedback));
      return throwError(feedback);
    }

    // if(err.status == 401){
    //   window.location.href = '/';
    //   return;
    // }

    const feedback = <IFeedbackModel> {
      type: 'ERR',
      code: 'NOKXXX',
      message: 'An error has occured. Please try again and if the error persists contact support.'
    };
    console.log('ERR2', err, JSON.stringify(feedback));
    return throwError(feedback);
  }
}
