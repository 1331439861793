import {Component, OnInit, EventEmitter, Input, Output, Renderer2} from '@angular/core';
import {ThemeService} from '../../services/theme.service';
import {LayoutService} from '../../services/layout.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthGuard} from 'app/shared/services/auth/auth.guard';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as userActions from '../../../views/user/state/user.actions';
import { AuthService } from 'app/views/user/auth.service';
import { CommonServiceService } from 'app/views/others/induster-pages/IndusterServices/common-service.service';
import { AppService } from 'app/shared/services/app.service';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;
  currentLang = 'en';
  public availableLangs = [{
    name: 'English',
    code: 'en',
  }, {
    name: 'Spanish',
    code: 'es',
  }];
  public egretThemes;
  public layoutConf: any;
  public appSettings: any;

  routerEventSub: Subscription;
  visible: boolean = true;
  currentDate:Date= new Date();
  defaultAvatar:any;
  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private authGuard: AuthGuard,
    public router: Router,
    private authService: AuthService,
    private appService: AppService

  ) {
    this.routerEventSub = this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((routeChange) => {
      this.visible =  this.router.url == '/agenda/sourcing-session-agenda' ||
                      this.router.url == '/sar/sar-dashboard' ||
                      this.router.url == '/user/user-dashboard';
    });
  }

  ngOnInit() {
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.layoutConf.sidebarStyle="compact";
    //this.translate.use(this.currentLang);
    this.appSettings = this.appService.getAppSettings();
  }

  ngOnDestroy() {
    if (this.routerEventSub) {
      this.routerEventSub.unsubscribe();
    }
  }

  //todo: comparision with 'program-list' and 'details' should not be hardcoded
  checkRoute(url: string): boolean{
    var splitted: string[] = url.split('/');
    return splitted[1] == 'program-list' && splitted[2] == 'details';
  }

  getName(){
    return this.authGuard.getProperty("name");
  }

  getCdsid(){
    let cdsid = this.authGuard.getProperty("cdsid");
    if(cdsid == null || cdsid == ''){
      return '';
    }
    return '(' + this.authGuard.getProperty("cdsid") + ')';
  }

  getPhotoUrl(){
    this.defaultAvatar = this.authGuard.getProperty("avatarUrl");
    if(this.defaultAvatar == null || this.defaultAvatar.trim() == "") {
      this.defaultAvatar = "/assets/images/person.png";
    }
    return this.defaultAvatar;
  }

  navigateToProfile(){
    let userId = this.authGuard.getProperty('id');
    // this.store.dispatch(new userActions.SelectedUserId(userId));
    // this.store.dispatch(new userActions.GetUser(userId));
    this.router.navigate(['/user/user-details']);
  }
  signout(){
    this.authService.logout().subscribe(x => { });
    this.router.navigate(['/sessions/signin']);


  }

  setLang(e) {
    this.translate.use(this.currentLang);
  }

  changeTheme(theme) {
    this.themeService.changeTheme(this.renderer, theme);
  }

  toggleNotific() {
    this.notificPanel.toggle();
  }

  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    });
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      }, {transitionClass: true});
    }

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact'
    }, {transitionClass: true});

  }
  back()
  {
    window.history.go(-1);
  }
}
