

import {Injectable, Inject} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationCancel} from '@angular/router';
import {isDevMode} from '@angular/core';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {SESSION_STORAGE, WebStorageService} from 'ngx-webstorage-service';
import { NavigationService } from '../navigation.service';

@Injectable()
export class AuthGuard implements CanActivate {
  private isAuthenticated = false; // Set this value dynamically
  private userData = null;
  public returnUrl : string = null;

  public isMobile: boolean;
  
  public BuyerRoleName : string = 'Buyer';
  public ManagerRoleName : string = 'Manager';
  public DirectorRoleName : string = 'Director';
  public ChairmanRoleName : string = 'MeetingCoordinator';
  public AdminRoleName : string = 'Admin';
  public ObserverRoleName : string = 'Observer';
  public FinanceRoleName : string = 'Finance';
  public DirectorOfPurchasingRoleName: string = "DirectorOfPurchasing";
  public SVPOperationsRoleName: string = "SVPOperations";
  public CEORoleName: string = "CEO";
  public CFORoleName: string = "CFO";
  public PurchasingVPRoleName: string = "PurchasingVP";
  public RegionalFinanceDirectorRoleName: string = "RegionalFinanceDirector";
  public VPControllerRoleName: string = "VPController";
  public PurchasingSVPRoleName: string = "PurchasingSVP";
  public VicePresidentRoleName: string = "VicePresident";
  public RegionalVPGMRoleName: string = "RegionalVPGM";
  public SVPEngineeringRoleName: string = "SVPEngineering";
  public SuperUserRoleName: string = "SuperUser";
  
  @BlockUI() blockUI: NgBlockUI;

  static singletonInstance: AuthGuard;

  constructor(
    private router: Router,
    @Inject(SESSION_STORAGE) private storage: WebStorageService,
    private navigation: NavigationService
    ) {
      //added this validation because authGuard was beeing called witha a new instance when router changes to otherModule
      //so this way, he keeps getting called in that otherModule but uses the same instance
      if (!AuthGuard.singletonInstance){
        AuthGuard.singletonInstance = this;
      }
      return AuthGuard.singletonInstance;

      //this.userData = storage.get("SARUserData");
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isMobile) return false;

    this.redirectIfNeed(state);

    if (this.isAuthenticated) {
      return true;
    }
    if(isDevMode() && this.userData != null){
      console.log(this.userData);
    }
    if(typeof(this.userData) != 'undefined' && this.userData != null){
      var d = new Date(this.userData.expiration);
      if(d.getTime() > new Date().getTime()){
        this.isAuthenticated = true; 
        return true;
      }
    }
    this.returnUrl = state.url;
    this.logout();
    return false;
  }

  private redirectIfNeed(state: RouterStateSnapshot){
    if (this.isFinance() && state.url == "/sar/sar-dashboard"){
      this.router.navigate(["/configurations/cost-center"]);
    }
  }

  logout(){
    this.userData = null;
    this.isAuthenticated = false;
    this.storage.remove("SARUserData");
    this.storage.remove("SARAuthKey");
    let args = {};
    if(this.returnUrl != null && this.returnUrl != '' && this.returnUrl != '/dashboard'){
      args = { queryParams: { returnUrl: this.returnUrl } };
    }
    this.router.navigate(['/sessions/signin'], args);
  }

  doLogin(data : any){
    this.userData = data;
    //this.storage.set("SARUserData", data);
    if(isDevMode()){
      console.log(this.userData);
    }
    this.isAuthenticated = true;
    //this.blockUI.stop();
  } 

  isManager(){ return this.isInRole(this.ManagerRoleName); }
  isBuyer(){ return this.isInRole(this.BuyerRoleName); }
  isDirector(){ return this.isInRole(this.DirectorRoleName); }
  isChairman(){ return this.isInRole(this.ChairmanRoleName); }
  isAdmin(){ return this.isInRole(this.AdminRoleName) || this.isInRole(this.SuperUserRoleName); }
  isObserver(){ return this.isInRole(this.ObserverRoleName); }
  isFinance(){ return this.isInRole(this.FinanceRoleName); }
  isSuperUser() { return this.isInRole(this.SuperUserRoleName); }

  isApprover(){
    return this.isInRole(this.DirectorOfPurchasingRoleName) ||
      this.isInRole(this.SVPOperationsRoleName) ||
      this.isInRole(this.CEORoleName) ||
      this.isInRole(this.CFORoleName) ||
      this.isInRole(this.PurchasingVPRoleName) ||
      this.isInRole(this.RegionalFinanceDirectorRoleName) ||
      this.isInRole(this.VPControllerRoleName) ||
      this.isInRole(this.PurchasingSVPRoleName) ||
      this.isInRole(this.VicePresidentRoleName) ||
      this.isInRole(this.RegionalVPGMRoleName);
  }

  isInRole(role : string){
    if(this.userData == null) return;
    if(this.userData.roles.length == 0) return;
    for(var i = 0; i < this.userData.roles.length; i++){
      if(this.userData.roles[i].toLowerCase() == role.toLowerCase()){
        return true;
      }
    }
    return false;
  }

  getProperty(key : string){
    if(this.userData == null) return '';
    return this.userData[key];
  }
  
  setIsAuthenticated(isAuthenticaded: boolean) {
    this.isAuthenticated = isAuthenticaded;
  }
  getColor(userId : string){
    return "#eee";
  }

  isDelegate(): boolean{
    return this.userData && this.userData.isDelegate;
  }

}
