import { HttpClient,HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'app/app-config.module';

@Injectable({
  providedIn: 'root'
})
export class SupplierServiceService {

  constructor(private config:AppConfig,private _http:HttpClient) { }
  private url:string=this.config.getConfig('apiEndpoint');

  getAllSupplier(plantId : string = null, all : number = 0){
    return this._http.get(this.url +`Supplier?plantId=${plantId}&all=${all}`);
  }

}
