import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from 'app/shared/services/layout.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html'
})
export class SidenavComponent implements OnInit {
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;
  @Output() helpClicked = new EventEmitter<void>();

  public layoutConf: any = {};

  constructor(private router: Router, private layout: LayoutService) {
    this.layoutConf = this.layout.layoutConf;
    console.log("layout", this.layout, this.layoutConf);

  }
  
  get currentRoute(): string{
    return this.router.url;
  }

  ngOnInit() {
    
  }

  openHelp(){
    this.helpClicked.emit();
  }
}