import {NgModule, APP_INITIALIZER, LOCALE_ID} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule,NoopAnimationsModule} from '@angular/platform-browser/animations';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';


import {rootRouterConfig} from './app.routing';
import {SharedModule} from './shared/shared.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

/* NgRx */
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {refdataReducer} from './views/refdata/state/refdata.reducer';
import {coreReducer} from './views/core/state/core.reducer';
import {RefdataEffects} from './views/refdata/state/refdata.effects';
import {userReducer} from './views/user/state/user.reducer';
import {UserEffects} from './views/user/state/user.effects';
import {ApiInterceptorService} from './api-interceptor.service';
import {CoreModule} from './views/core/core.module';
import { BlockUIModule } from 'ng-block-ui';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { AppConfig } from './app-config.module';
import { HttpModule } from '@angular/http';
import { EditorModule } from '@progress/kendo-angular-editor';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { MatDialogModule } from '@angular/material/dialog';
import { DatePipe, registerLocaleData } from '@angular/common';
import { ScrollViewModule } from "@progress/kendo-angular-scrollview";
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { SupplierServiceService } from './views/others/induster-pages/IndusterServices/supplier-service.service';
import { FacilitesServiceService } from './views/others/induster-pages/IndusterServices/facilites-service.service';
import { PurchaseOrderServiceService } from './views/others/induster-pages/IndusterServices/purchase-order-service.service';
import { CommonServiceService } from './views/others/induster-pages/IndusterServices/common-service.service';
import { ServiceWorkerModule } from '@angular/service-worker';



// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { IntlModule } from "@progress/kendo-angular-intl";
import "@progress/kendo-angular-intl/locales/pt/all";
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt, 'pt-PT');

/*
//TD: Not in use!?
const JWT_Module_Options: JwtModuleOptions = {
  config: {
  }
};
*/

// Add this function
export function initConfig(config: AppConfig){
  return () => config.load()
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    SharedModule,
    HttpClientModule,
    PerfectScrollbarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(rootRouterConfig, {useHash: false}),
    StoreModule.forRoot({core: coreReducer, refdata: refdataReducer, user: userReducer}),
    EffectsModule.forRoot([RefdataEffects, UserEffects]),
    StoreDevtoolsModule.instrument({
      name: 'AMF DevTools',
      maxAge: 25,
      logOnly: environment.production
    }),
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    CoreModule,
    BlockUIModule.forRoot(),
    GridModule,
    InputsModule,
    LayoutModule,
    HttpModule,
    EditorModule,
    ToolBarModule,
    ListViewModule,
    MatDialogModule,
    ScrollViewModule,
    NavigationModule,
    DialogsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  declarations: [AppComponent],
  providers: [
    {provide: MATERIAL_SANITY_CHECKS, useValue: false},
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true},
    {provide: LOCALE_ID, useValue: "pt-PT" },
    AppConfig,
    DatePipe,
    { provide: APP_INITIALIZER,
      useFactory: initConfig, // And use it here
      deps: [AppConfig],
      multi: true } ,
    HttpClientModule,CommonServiceService,
    SupplierServiceService,FacilitesServiceService,PurchaseOrderServiceService

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
