import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'app/app-config.module';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderServiceService {

  constructor(private _http:HttpClient,private config:AppConfig) { }
  private url:string=this.config.getConfig('apiEndpoint');


  getAllPurchaseOrders(plantId, supplierId, docNumber) {
    return this._http.get(this.url+'PurchaseOrder?plantId=' + plantId + '&supplierId=' + supplierId + '&docNumber=' + (docNumber || ''));
  }


  // Post Product
  savePOProducts(list){
    return this._http.put(this.url+'PurchaseOrder',{items: list})
   }
}
