<ul *ngIf="currentData">
    <li *ngIf="showFilter" style="padding: 0px;">
        <input class="k-input k-textbox k-filter-search-input" (input)="onInput($event)" placeholder="Search..."/>
    </li>
    <li #itemElement *ngFor="let item of currentData; let i = index"
        (click)="onSelectionChange(valueAccessor(item), itemElement)"
        [ngClass]="{ 'k-state-selected': isItemSelected(item) }">

        <input
            type="checkbox"
            id="chk-{{ valueAccessor(item) }}"
            (focus)="onFocus(itemElement)"
            class="k-checkbox-md k-rounded-md"
            [checked]="isItemSelected(item)"
        />
        <label
            class="k-multiselect-checkbox k-checkbox-label"
            for="chk-{{ valueAccessor(item) }}"
        >
            {{ textAccessor(item) }}
      </label>
    </li>
</ul>
<div *ngIf="!currentData" style="text-align: center; margin: 20px;">
    No available data to filter
</div>
