import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonServiceService {

  constructor() { }
  // creating subject to store value of LocName & LocId &selectedModule start
  selectedLocationName=new BehaviorSubject('');
  selectedLocationId=new BehaviorSubject('');
  selectedModuleId=new BehaviorSubject('');
  showNumberKeyboard=new BehaviorSubject(false);
  // End

  // creating subject to store value of SupplierId from Product screen
  selectedSpplierFrom_Prod=new BehaviorSubject('');

  // NewProduct object
  NewProduct=new Subject();


  // function to save Prod
  saveNewProduct(newItem){
    let newProdlist=[];
    if(localStorage.getItem('newPd')){
      newProdlist=JSON.parse(localStorage.getItem('newPd'));
      newProdlist=[newItem,...newProdlist]
    }else{
      newProdlist=[newItem];
    }
    localStorage.setItem('newPd',JSON.stringify(newProdlist));
    return "Success";
  }
// getting items
  fetchNewProducts(){
    let allNewproducts=[];
    if(localStorage.getItem('newPd')){
      allNewproducts=JSON.parse(localStorage.getItem('newPd'));
    }
    return allNewproducts;
  }

  fetchNewProductsbyId(id){
    let product=[];
    if(localStorage.getItem('newPd')){
      product=JSON.parse(localStorage.getItem('newPd'));
      const singleProd=product.find((val:any)=>val.id==id);
      return singleProd;
    }
  }

  deleteItem(id){
    if(localStorage.getItem('newPd')){
      let getProduct=JSON.parse(localStorage.getItem('newPd'));
      let isPresent=getProduct.findIndex((val)=> val.id==id);

      if(isPresent!=-1){
      getProduct.splice(getProduct.findIndex((val:any)=>val.id==id),1);
      localStorage.setItem('newPd',JSON.stringify(getProduct));
      }

    }
    if(localStorage.getItem('API')){
      let getProductAPI=JSON.parse(localStorage.getItem('API'));
      let isPresent=getProductAPI.findIndex((val)=> val.id==id);
      console.log("commonserv"+isPresent)

      if(isPresent!=-1){
        getProductAPI.splice(getProductAPI.findIndex((val:any)=>val.id==id),1);
        localStorage.setItem('API',JSON.stringify(getProductAPI));
      }

    }

    return "deleted";
  }
  // deleting Weight
  deleteWeightItem(id){
    let res='Id Not found';
    if(localStorage.getItem('newPd')){
      let getProduct=JSON.parse(localStorage.getItem('newPd'));
      let isPresent=getProduct.findIndex((val)=> val.id==id);
        if(isPresent!=-1){
            getProduct.splice(getProduct.findIndex((val:any)=>val.id==id),1);
            localStorage.setItem('newPd',JSON.stringify(getProduct));
            res='deleted'
        }
      return res;
    }
  }



 // function to save histroy
 saveHistoryItems(newItem){
  let newProdlist=[];
  if(localStorage.getItem('Histroy')){
    newProdlist=JSON.parse(localStorage.getItem('Histroy'));
    newProdlist=[...newItem,...newProdlist]
  }else{
    newProdlist=newItem;
  }
  localStorage.setItem('Histroy',JSON.stringify(newProdlist));
}


 // deleting Histroy Item
 updateHistroyItem(id){
  if(localStorage.getItem('Histroy')){
    let getHistroyProduct=JSON.parse(localStorage.getItem('Histroy'));
    let isPresent=getHistroyProduct.findIndex((val)=> val.id==id);
      if(isPresent!=-1){
        getHistroyProduct.splice(getHistroyProduct.findIndex((val:any)=>val.id==id),1);
          localStorage.setItem('Histroy',JSON.stringify(getHistroyProduct));
          return 'deleted';
      }
  }
}






  // Clearing localStorage
  clearLocalStorageByItem(name){
    localStorage.removeItem(name);
  }
  clearlocalStorage(){
    localStorage.clear();
  }

}
