import {Routes} from '@angular/router';
import {AdminLayoutComponent} from './shared/components/layouts/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from './shared/components/layouts/auth-layout/auth-layout.component';
import {AuthGuard} from './shared/services/auth/auth.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    data: {}
  }/*,
  {
    path: '',
    redirectTo: 'program-list/overview',
    pathMatch: 'full',
    data: {}
  }*/,
  {
    path: '',
    component: AuthLayoutComponent,
    data: {},
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: {title: 'Session'}
      },
      {
        path: 'public',
        loadChildren: () => import('./views/public/public.module').then(m => m.PublicModule),
        data: {title: 'Public'}
      }
    ]
  },{
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    data: {animation: 'Home'},
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule),
        data: { title: 'Dashboard', breadcrumb: 'Dashboard',animation: 'Home'}
      },
      {
        path: 'admin',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule),
        data: { title: 'Admin', breadcrumb: 'Admin',animation: 'Home'}
      },
      {
        path: 'configurations',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule),
        data: { title: 'Configurations', breadcrumb: 'Configurations',animation: 'Home'}
      },
      {
        path: 'super',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule),
        data: { title: 'Super', breadcrumb: 'Super', animation: 'Home'}
      },
      {
        path: 'user',
        loadChildren: () => import('./views/user/user.module').then(m => m.UserModule),
        data: { title: 'Users', breadcrumb: 'Users',animation: 'Home'}
      },
      {
        path: 'induster',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule),
        data: {title: 'Induster', breadcrumb: 'Induster', animation: 'Home'}
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

